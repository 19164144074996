











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { CHANGE_CONCERNED_COURT } from '@/modules/conservatorship/store/actions';
import DatePicker from '@/components/form/DatePicker.vue';
import CourtSelect from '@/modules/court/components/CourtSelect.vue';
import { ChangeConcernedCourt, ConservatorshipDetails } from '../../../types';
import { ApiResponse } from '@/components/types';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    DatePicker,
    CourtSelect
  }
})
export default class ConcernedCourtChangeDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;

  @Conservatorship.Action(CHANGE_CONCERNED_COURT) changeCourt!: (changeCourt: ChangeConcernedCourt) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: ChangeConcernedCourt = {
    court_hold: format(new Date(), 'yyyy-MM-dd'),
    court_id: this.conservatorship.court.id,
    attorney_docket: this.conservatorship.attorneyDocket,
    conservatorship_id: this.conservatorship.id
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        court_hold: format(new Date(), 'yyyy-MM-dd'),
        court_id: this.conservatorship.court.id,
        attorney_docket: this.conservatorship.attorneyDocket,
        conservatorship_id: this.conservatorship.id
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.changeCourt(this.value);
  }
}
